
import { GameModel } from './model';
import { GameScene } from './hockey';

var renderer;
var gameScene;

var requestAnimationFrame = window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function (callback) {
        window.setTimeout(callback, 1000 / 60);
    };


function init() {

    var canvas = document.createElement("canvas");
    canvas.screencanvas = true; //for cocoonjs
    var dpr = window.devicePixelRatio;
    canvas.width = window.innerWidth * dpr;
    canvas.height = window.innerHeight * dpr;
    renderer = new window.THREE.WebGLRenderer({ antialias: true, canvas: canvas });
    renderer.setClearColor(0x222324);
    renderer.setSize(canvas.width, canvas.height);
    document.getElementById("container").appendChild(canvas);
    canvas.style.cssText = "position:absolute;padding:0;margin:0;width:100%;height:100%";

    var model = new GameModel();
    // model.createGUI();
    gameScene = new GameScene(renderer, model);

    requestAnimationFrame(render);

    if(isMobile()) {
        model.setDifficulty(0.6);
    }
    
}

function render() {
    gameScene.render();
    requestAnimationFrame(render);
}

function isMobile() {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
}

window.onload = init;
